<template>
  <div class="container"><br>  
    <div class="col-12 text-center g">
        <h4>
            <img :src="href + '/files/weeklyplan.png'" alt="" style="width: 40px">
            خطة التعلم الاسبوعية
            <br>
            <small>{{saved.schoolname}}</small>
        </h4>
    </div>
    <div class="card" style="border: 2px solid #888; border-radius: 20px">
        <div class="card-header">
            <h3 id='ffff'>
                خطة التعلم الاسبوعية 
                <u id="fromto"></u>
            </h3>
        </div>
        <b-modal id="modal-s" title="ارسال رسائل لاولياء الامور" hide-footer>
            <div class="row" v-if="type == 0 && students.length">
                <div class="col-12">
                    اختر نوع الارسال:
                </div>
                <div class="col-12 col-lg-6 g text-center">
                    <div class="card card-body hoverxr border" @click="type=1">
                        <img :src="require('@/assets/images/wp-type-1.png')" alt="">
                        <br>
                        ارساله على شكل رابط الكتروني يكون فيه بيانات الخطة مع اسماء المعلمين<br>
                        <strong class="text-success">ويمكن للمعلمين التعديل عليه بعد ارساله</strong>
                    </div>
                </div>
                <div class="col-12 col-lg-6 g text-center">
                    <div class="card card-body hoverxr border" @click="type=2">
                        <img :src="require('@/assets/images/wp-type-2.png')" alt="">
                        <br>
                        ارساله على شكل ملف PDF كل مادة في صفحة مع اسم المعلم<br>

                        <strong class="text-warning">ولا يمكن للمعلمين التعديل عليه بعد ارساله</strong>
                    </div>
                </div>
            </div>
            <div v-if="students.length && type != 0 && !pdf_loading">
                <p>
                    اختر المفصول المراد الارسال لها:
                </p>
                <div v-if="students.length">
                    <template v-for="(a,b) in classes">
                        <div class="form-check" :key="b">
                        <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" v-model="selected_classes" :value="b">
                            {{b}}
                        </label>
                        </div>
                    </template>
                </div>
                <div class="col-12 text-center g" v-if="students.length">
                    <button class="btn btn-primary" @click="sendtostudents()">
                        ارسال الآن
                    </button>
                </div>
                <template v-if="Object.keys(pdfs).length">
                    <hr>
                    <table class='table table-bordered table-sm table-hover'>
                        <thead>
                            <th>
                                الفصل
                            </th>
                            <th>
                                الملف
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(p, x) in pdfs" :key="x">
                                <td>
                                    {{ x }}
                                </td>
                                <td>
                                    <a :href="p" target="_blank" class="btn btn-sm btn-primary" style="border-radius: 0px">
                                        <i class="fa fa-file"></i>
                                        معاينة
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
            <div v-if="pdf_loading">
                <img :src="require('@/assets/images/loading.svg')" alt="">
                <br>جاري انشاء الملفات... (تم {{ Object.keys(pdfs).length }} من {{ selected_classes.length }})
            </div>
            <div class="col-12 text-center g" v-if="!students.length">
                <div class="alert alert-warning text-center g">
                    <span v-if="loading_students">
                        <img :src="require('@/assets/images/loading.svg')" alt="">
                    </span>
                    <span v-if="!loading_students">
                        <span class="text-dark">برجاء تحديث بيانات الطلاب للاداة للتعرف على الطلاب.
                        <br>
                        1->
                        قم بالضغط على الاداة في مدرستي <br>2-> اضغط على خدمات الطلاب <br>3-> اضغط على تحديث بيانات الطلاب من مدرستي.</span>
                    </span>
                </div>
            </div>
        </b-modal>
        <div class="card-footer text-center">
            <button class="btn btn-primary" style="border-radius: 0px" @click="save()">
                <i class="fa fa-save"></i>
                حفظ/تحديث الخطة
                <br>
                <small v-if="saved._id">آخر تحديث: {{ saved.last_save }}</small>
            </button>&nbsp;
            <button class="btn btn-success" v-b-modal.modal-s @click="type=0" style="border-radius: 0px; background: #7695FF !important; border: none">
                <i class="fa fa-paper-plane"></i>
                ارسال لأولياء الأمور
            </button>&nbsp;
            <button class="btn btn-secondary" @click="senders = teachers; send()" style="border-radius: 0px; background: #C8A1E0 !important; border: none">
                <i class="fa fa-paper-plane"></i>
                ارسال للمعلمين
            </button>
        </div>
        <div class="card-body" style="margin-top: -20px">
            <app-collapse>
                <app-collapse-item v-for="(data, classname, i) in classes" :key="classname" class="itemm" :title="'🗓️ ' + classname" :id="`classname_${i}`">
                    <button class="btn btn-primary btn-sm" style="margin-bottom: 3px; border-radius: 0px" @click="copyplan(classname)">
                        <i class="fa fa-copy"></i>
                        نسخ رابط هذه الخصة
                    </button>&nbsp;
                    <button class="btn btn-warning btn-sm" style="margin-bottom: 3px; border-radius: 0px" @click="printDiv(`classname_${i}`)">
                        <i class="fa fa-print"></i>
                        طباعة خطة الفصل
                    </button>
                    <template v-for="(d, id) in data">
                        <template v-if="datesData2[classname]">
                        <app-collapse-item :key="id" v-if="datesData2[classname][id]" class="itemm" :title="'👤 ' + ids2_names[id]">
                            <button class="btn btn-warning btn-sm" style="margin-bottom: 3px; border-radius: 0px" @click="printDiv(`p_${classname}${id}`)">
                                <i class="fa fa-print"></i>
                                طباعة هذه الخصة
                            </button>
                            <div class="col-12 table-responsive" :id="`p_${classname}${id}`">
                                <table class="table table-sm table-bordered table-striped table-hover bg-white">
                                    <thead>
                                        <th>
                                            المادة
                                        </th>
                                        <th>
                                            
                                        </th>
                                        <th>
                                            الأحد
                                            <br>
                                            <span>{{ dates[0] }}</span>
                                        </th>
                                        <th>
                                            الاثنين
                                            <br>
                                            <span>{{ dates[1] }}</span>
                                        </th>
                                        <th>
                                            الثلاثاء
                                            <br>
                                            <span>{{ dates[2] }}</span>
                                        </th>
                                        <th>
                                            الاربعاء
                                            <br>
                                            <span>{{ dates[3] }}</span>
                                        </th>
                                        <th>
                                            الخميس 
                                            <br>
                                            <span>{{ dates[4] }}</span>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <template v-for="(subject) in subjectsNames(d)">
                                            <tr :key="subject">
                                                <td rowspan="3" style="padding: 0px" class="text-center">
                                                    <span v-if="printing">{{ titles[subject] }}</span>
                                                    <input type="text" style="padding: 10px; border: 1px solid #ddd;" v-if="!printing" v-model="titles[subject]" placeholder="اسم المادة هنا...">
                                                </td>
                                                <td>الدرس</td>
                                                <td>
                                                    <span v-if="d[dates[0]]">
                                                        <span v-if="d[dates[0]][subject]">
                                                            {{ d[dates[0]][subject].Title }}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[1]]">
                                                        <span v-if="d[dates[1]][subject]">
                                                            {{ d[dates[1]][subject].Title }}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[2]]">
                                                        <span v-if="d[dates[2]][subject]">
                                                            {{ d[dates[2]][subject].Title }}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[3]]">
                                                        <span v-if="d[dates[3]][subject]">
                                                            {{ d[dates[3]][subject].Title }}
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[4]]">
                                                        <span v-if="d[dates[4]][subject]">
                                                            {{ d[dates[4]][subject].Title }}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr :key="subject + '1'">
                                                <td style="text-align: center">الاهداف</td>
                                                <td>
                                                    <span v-if="d[dates[0]]">
                                                        <span v-if="d[dates[0]][subject]">
                                                            <span v-if="lessons[d[dates[0]][subject].Data]">
                                                                <span v-if="goals[lessons[d[dates[0]][subject].Data].id]">
                                                                    <span v-if="decodeURIComponent(goals[lessons[d[dates[0]][subject].Data].id].goals) != 'undefined' && decodeURIComponent(goals[lessons[d[dates[0]][subject].Data].id].goals) != undefined">
                                                                        <span v-html="decodeURIComponent(goals[lessons[d[dates[0]][subject].Data].id].goals).replace('undefined', '')"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[1]]">
                                                        <span v-if="d[dates[1]][subject]">
                                                            <span v-if="lessons[d[dates[1]][subject].Data]">
                                                                <span v-if="goals[lessons[d[dates[1]][subject].Data].id]">
                                                                    <span v-if="decodeURIComponent(goals[lessons[d[dates[1]][subject].Data].id].goals) != 'undefined' && decodeURIComponent(goals[lessons[d[dates[1]][subject].Data].id].goals) != undefined">
                                                                        <span v-html="decodeURIComponent(goals[lessons[d[dates[1]][subject].Data].id].goals).replace('undefined', '')"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[2]]">
                                                        <span v-if="d[dates[2]][subject]">
                                                            <span v-if="lessons[d[dates[2]][subject].Data]">
                                                                <span v-if="goals[lessons[d[dates[2]][subject].Data].id]">
                                                                    <span v-if="decodeURIComponent(goals[lessons[d[dates[2]][subject].Data].id].goals) != 'undefined' && decodeURIComponent(goals[lessons[d[dates[2]][subject].Data].id].goals) != undefined">
                                                                        <span v-html="decodeURIComponent(goals[lessons[d[dates[2]][subject].Data].id].goals).replace('undefined', '')"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[3]]">
                                                        <span v-if="d[dates[3]][subject]">
                                                            <span v-if="lessons[d[dates[3]][subject].Data]">
                                                                <span v-if="goals[lessons[d[dates[3]][subject].Data].id]">
                                                                    <span v-if="decodeURIComponent(goals[lessons[d[dates[3]][subject].Data].id].goals) != 'undefined' && decodeURIComponent(goals[lessons[d[dates[3]][subject].Data].id].goals) != undefined">
                                                                        <span v-html="decodeURIComponent(goals[lessons[d[dates[3]][subject].Data].id].goals).replace('undefined', '')"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[4]]">
                                                        <span v-if="d[dates[4]][subject]">
                                                            <span v-if="lessons[d[dates[4]][subject].Data]">
                                                                <span v-if="goals[lessons[d[dates[4]][subject].Data].id]">
                                                                    <span v-if="decodeURIComponent(goals[lessons[d[dates[4]][subject].Data].id].goals) != 'undefined' && decodeURIComponent(goals[lessons[d[dates[4]][subject].Data].id]) != undefined">
                                                                        <span v-html="decodeURIComponent(goals[lessons[d[dates[4]][subject].Data].id].goals).replace('undefined', '')"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr :key="subject + '2'">
                                                <td style="text-align: center">الواجبات</td>
                                                <td>
                                                    <span v-if="d[dates[0]]">
                                                        <span v-if="d[dates[0]][subject]">
                                                            <span v-if="lessons[d[dates[0]][subject].Data]">
                                                                {{ lessons[d[dates[0]][subject].Data].homeworks_html }}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[1]]">
                                                        <span v-if="d[dates[1]][subject]">
                                                            <span v-if="lessons[d[dates[1]][subject].Data]">
                                                                {{ lessons[d[dates[1]][subject].Data].homeworks_html }}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[2]]">
                                                        <span v-if="d[dates[2]][subject]">
                                                            <span v-if="lessons[d[dates[2]][subject].Data]">
                                                                {{ lessons[d[dates[2]][subject].Data].homeworks_html }}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[3]]">
                                                        <span v-if="d[dates[3]][subject]">
                                                            <span v-if="lessons[d[dates[3]][subject].Data]">
                                                                {{ lessons[d[dates[3]][subject].Data].homeworks_html }}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="d[dates[4]]">
                                                        <span v-if="d[dates[4]][subject]">
                                                            <span v-if="lessons[d[dates[4]][subject].Data]">
                                                                {{ lessons[d[dates[4]][subject].Data].homeworks_html }}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </app-collapse-item>
                        </template>
                    </template>
                </app-collapse-item>
            </app-collapse>
        </div>
        <textarea class='get-local-storage' name="teachers" style='display: none'></textarea>
    </div><br><br>
    <b-modal id="modal-students-ghiab-manual" size="lg" title="ارسال رسائل " hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        المعلم
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody id="students-messages">
                    <template v-for="(student, c) in senders">
                      <tr :key="c">
                          <td>{{ student.phone }}</td>
                          <td>{{ student.name }}</td>
                          <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                      </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual"
      @click="sendModal()"
      v-b-modal.modal-students-ghiab-manual
    ></button>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    AppCollapse,
    AppCollapseItem,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            type: 0,
            saved: {},
            arr: [],
            tdones: [],
            selected_classes: [],
            teachers: [],
            loading: false,
            students: [],
            view_archive: false,
            message: `رابط الخطة الاسبوعية: {link}`,
            school_name: decodeURI(window.location.href.split("school_name=")[1].split("&")[0]),
            admin_name: decodeURI(window.location.href.split("admin_name=")[1].split("&")[0]),
            dates: [],
            teachers_phones: {},
            selected: "",
            datesData: {},
            href: window.location.href.split('/reports/')[0],
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            datesData2: {},
            ids_names: {},
            ids2_names: {},
            classes: [],
            lessons: {},
            goals: {},
            loading_students: true,
            printing: false,
            senders: [],
            titles: {},
            classes_teacheers: {},
            days: ["الاحد",'الاثنين','الثلاثاء','الاربعاء','الخميس'],
            subject_names: {},
            pdfs: {},
            pdf_loading: false
        }
    },
    created(){
        
        var g = this;
        $.post(api + "/api/reports/weeklyplan/get", {
            school_id: g.school_id,
        }).then(function(r){
            if(r.status != 100){
                return alert(r.response, 200)
            }
            g.saved = r.response;
        }).fail(function(){alert("حدث خطأ في الاتصال")})
        var teachers_data = {},
        classes_data = {},
        dates_data = {},
        dates_data2 = {},
        subjects_data = {};
        var v = setInterval(() => {
            if($(".get-local-storage-value[name='teachers']").length){
                if($(".get-local-storage[name='teachers-weeklyplan']").length == 0){
                    $("body").append(`<textarea class='get-local-storage' name="teachers-weeklyplan" style='display: none'></textarea>`)
                }
                if($(".get-local-storage-value[name='teachers-weeklyplan']").length){
                clearInterval(v) 
                }else{
                    return
                }
            }else{
                return;
            }
           $("#fromto").html(decodeURIComponent(window.location.href.split("date=")[1].split("&s")[0]))
            g.teachers = JSON.parse($(".get-local-storage-value[name='teachers']").val())
            g.teachers = g.teachers.map(function(x){
                x.isTeacher = true;
                return x;
            })
            g.arr = JSON.parse($(".get-local-storage-value[name='teachers-weeklyplan']").val())
             setTimeout(() => {
                $("body").append(`<textarea class='get-local-storage' name="teachers-weeklyplan-goals" style='display: none'></textarea>`)
                var xxs = setInterval(() => {
                    if($(".get-local-storage-value[name='teachers-weeklyplan-goals']").length){
                        clearInterval(xxs) 
                        g.goals = JSON.parse($(".get-local-storage-value[name='teachers-weeklyplan-goals']").val())
                        setTimeout(() => {
                        $("body").append(`<textarea class='get-local-storage' name="teachers-weeklyplan-subjects" style='display: none'></textarea>`)
                        var xxs = setInterval(() => {
                            if($(".get-local-storage-value[name='teachers-weeklyplan-subjects']").length){
                                clearInterval(xxs) 
                                g.subject_names = JSON.parse($(".get-local-storage-value[name='teachers-weeklyplan-subjects']").val())
                                //

                                r.teachers.forEach(teacher => {
                                    g.ids2_names[teacher.id] = teacher.name
                                    g.teachers_phones[teacher.id] = teacher.phone
                                    //
                                    teachers_data[teacher.id] = [];
                                    for (const [key, value] of Object.entries(r.arr[teacher.id]?.days ?? {})) {
                                        if(!r.arr[teacher.id]?.lessons[value.Data]?.notcreated){
                                            if(value.SubjectId){
                                                if(g.subject_names[value.SubjectId?.toString()]){
                                                    value.SubjectName = g.subject_names[value.SubjectId?.toString()];
                                                    g.titles[g.subject_names[value.SubjectId?.toString()]] = g.subject_names[value.SubjectId?.toString()];
                                                }
                                            }
                                            g.ids_names = teacher.name
                                            teachers_data[teacher.id].push(value);
                                            if(!classes_data[value.ClassRoomName]){
                                                classes_data[value.ClassRoomName] = {};
                                            }
                                        //    var d = new Date(new Number(value.Date.replace("Date(", "").replace(')', '').replace('/', '').replace('/', ''))).toISOString().split("T")[0]
                                            var d = value.Date.split("T")[0]
                                            if(!classes_data[value.ClassRoomName][teacher.id]){
                                                classes_data[value.ClassRoomName][teacher.id] = {};
                                            }
                                            if(!classes_data[value.ClassRoomName][teacher.id][d]){
                                                classes_data[value.ClassRoomName][teacher.id][d] = {};
                                            }
                                            if(!classes_data[value.ClassRoomName][teacher.id][d][value.SubjectName?.trim()]){
                                                classes_data[value.ClassRoomName][teacher.id][d][value.SubjectName?.trim()] = {};
                                            }
                                            classes_data[value.ClassRoomName][teacher.id][d][value.SubjectName?.trim()] = (value)
                                            if(!subjects_data[value.SubjectId]){
                                                subjects_data[value.SubjectId] = [];
                                            }
                                            subjects_data[value.SubjectId].push(value)
                                            if(!dates_data[d]){
                                                dates_data[d] = {};
                                            }
                                            if(!dates_data[d][value.ClassRoomName]){
                                                dates_data[d][value.ClassRoomName] = {};
                                            }
                                            if(!dates_data[d][value.ClassRoomName][teacher.id]){
                                                dates_data[d][value.ClassRoomName][teacher.id] = {};
                                            }
                                            dates_data[d][value.ClassRoomName][teacher.id] = (value)
                                            if(!dates_data2[value.ClassRoomName]){
                                                dates_data2[value.ClassRoomName] = {};
                                            }
                                            if(!dates_data2[value.ClassRoomName][teacher.id]){
                                                dates_data2[value.ClassRoomName][teacher.id] = {};
                                            }
                                            dates_data2[value.ClassRoomName][teacher.id] = (value)
                                            if(!g.dates.includes(d)){
                                                g.dates.push(d);
                                            }
                                        }
                                    }
                                    g.datesData = dates_data
                                    g.datesData2 = dates_data2
                                    g.classes = classes_data
                                    function compare( a, b ) {
                                    if ( a < b ){
                                        return -1;
                                    }
                                    if ( a > b ){
                                        return 1;
                                    }
                                    return 0;
                                    }
                                    g.dates.sort( compare );
                                    //
                                    if (r.arr[teacher.id]) {
                                        i++;
                                        var ethrat = 0;
                                        var homeworks = 0;
                                        var exams = 0;
                                        var actives = 0;
                                        var notcreated = 0;
                                        var total = 0
                                        t_t = t_t + r.arr[teacher.id]?.total_lessons
                                        for (const [key, value] of Object.entries(r.arr[teacher.id]?.lessons)) {
                                            g.lessons[key] = value
                                            if (value.ethrat > 0) {
                                                ethrat = ethrat + 1
                                                t_ethrat = t_ethrat + 1
                                            }
                                            if (value.homeworks > 0) {
                                                homeworks = homeworks + 1
                                                t_homeworks = t_homeworks + 1
                                            }
                                            if (value.exams > 0) {
                                                exams = exams + 1
                                                t_exams = t_exams + 1
                                            }
                                            if (value.actives > 0) {
                                                actives = actives + 1
                                                t_actives = t_actives + 1
                                            }
                                            if (value.notcreated) {
                                                notcreated = notcreated + 1;
                                                t_notcreated = t_notcreated + 1;
                                            } else {
                                                total = total + 1
                                                t_total = t_total + 1
                                            }
                                        }
                                    }
                                });
                                //
                            }
                        }, 100);
                    }, 100);
                    }
                }, 100);
             }, 100);
            setTimeout(() => {
                $("body").append(`<textarea class='get-local-storage' name="m_students_${g.school_id}" style='display: none'></textarea>`)
                    var xxs2 = setInterval(() => {
                        if($(`.get-local-storage-value[name='m_students_${g.school_id}']`).length){
                            clearInterval(xxs2) 
                            if($(`.get-local-storage-value[name='m_students_${g.school_id}']`).val() != "undefined"){
                                g.students = JSON.parse($(`.get-local-storage-value[name='m_students_${g.school_id}']`).val())
                            }
                        }
                }, 100);
                g.loading_students = false;
            }, 5000);
            var r = {
                teachers: g.teachers,
                arr: g.arr
            }
            var i = 0;
            var t_ethrat = 0;
            var t_homeworks = 0;
            var t_exams = 0;
            var t_actives = 0;
            var t_notcreated = 0;
            var t_t = 0;
            var t_total = 0;
        }, 100);
    },
    methods: {
        copyplan(classname){
                var g = this;
                $.post(api + '/api/messages/short', {
                school_id: g.school_id,
                arr: JSON.stringify([{
                    phone: "",
                    message: `({${g.href + '/weeklyplan/' + g.school_id + "/" + encodeURIComponent(classname)}})`
                }]),
            }).then(function(r){
              navigator.clipboard.writeText(r.response[0].message);
              prompt("تم النسخ بنجاح!", r.response[0].message)
            }).fail(function(){
                navigator.clipboard.writeText(`${g.href + '/weeklyplan/' + g.school_id + "/" + encodeURIComponent(classname)}`);
              prompt("تم النسخ بنجاح!.", `${g.href + '/weeklyplan/' + g.school_id + "/" + encodeURIComponent(classname)}`)
            })
        },
        save(){
            var g = this;
            if(!g.school_id || g.school_id == ""){
                return alert("برجاء الدخول عن طريق المنصة. لم يتم التعرف على الحساب.")
            }
            if(g.saved._id){
                if(!confirm("متأكد من التحديث؟ سوف يتم فقد اي تعديلات او اضافات المعلمين للخطة السابقة.")){return;}
            }
            var teachers_obj = {};
            for (const [classroom, teachers] of Object.entries(g.classes)) {
                for (const [teacher_id, teacher_data] of Object.entries(teachers)) {
                    if(!teachers_obj[teacher_id]){
                        teachers_obj[teacher_id] = {
                            classrooms: {},
                            name: g.ids2_names[teacher_id]
                        };
                    }
                    if(!teachers_obj[teacher_id].classrooms[classroom]){
                        teachers_obj[teacher_id].classrooms[classroom] = {days: {}};
                    }
                    var names = {}, i=0;
                    for (const [d, ddata] of Object.entries(teacher_data)) {
                        if(!teachers_obj[teacher_id].classrooms[classroom]?.days[d]){
                            teachers_obj[teacher_id].classrooms[classroom].days[d] = {}; 
                        }
                         for (const [d2, ddata2] of Object.entries(ddata)) {
                            if(!names[d2]){
                                i++
                                names[d2] = g.subject_names[ddata2.SubjectId]
                            }
                            var dx = g.titles[d2] ?? names[d2];
                            if(g.titles[d2]){
                                var f = 0;
                                for (const [key, value] of Object.entries(g.titles)) {
                                    if(value == g.titles[d2]){
                                        f++;
                                    }
                                }
                                if(f > 1){
                                    g.titles[d2] = g.titles[d2] + "."
                                    var dx = g.titles[d2] ?? names[d2];
                                }
                            }
                            ddata2.goal = decodeURIComponent(g.goals[g.lessons[ddata2.Data].id].goals);
                            ddata2.url = decodeURIComponent(g.goals[g.lessons[ddata2.Data].id].url);
                            ddata2.videos = g.goals[g.lessons[ddata2.Data].id].videos;
                            ddata2.homeworks = g.lessons[ddata2.Data].homeworks_html
                            ddata2.teacher = g.ids2_names[teacher_id]
                            teachers_obj[teacher_id].classrooms[classroom].days[d][dx] = ddata2
                        }
                    }
                }
            }
            $.post(api + "/api/reports/weeklyplan/save", {
                school_id: g.school_id,
                week: decodeURIComponent(window.location.href.split("date=")[1].split("&s")[0]).replace("&nbsp;", " "),
                data: JSON.stringify(teachers_obj),
                dates: JSON.stringify(g.dates),
                schoolname: decodeURIComponent(window.location.href.split("school_name=")[1].split("&a")[0]) ?? "-",
            }).then(function(r){
                alert("تم الحفظ بنجاح!")
                g.saved = r.response;
            })
        },
        printDiv(id, x=false) {
            this.printing = true;
            if(id.includes("classname")){
                $(`#${id} [aria-expanded="false"]`).click()
            }
            setTimeout(() => {
                var divToPrint=document.getElementById(id);
                var newWin= window.open(""), g = this;;
                newWin.document.write(divToPrint.outerHTML + `<style>
                #headerTable{
                    position: relative;
                top: -100px;
                width: 90%;
                }
                    body{
                        direction: rtl;
                        text-align: right
                    }
                    thead{
                        background: #eee;
                    }
                    table, td, th {
                    border: 1px solid;
                    text-align: center
                    }
                    tr:nth-child(even) {
                        background-color: #f2f2f2;
                    }
                    table {
                    width: 100%;
                    border-collapse: collapse;
                    }
                    button{
                        display:none
                    }
                    table{
                        border-collapse:collapse;
                    }
 table {
     table-layout: fixed !important;
     width: 100% !important;
 }

 table td {
     
 }
                    </style>`);
                newWin.document.close();
                newWin.print();
                if(id.includes("classname")){
                    $(`#${id} [aria-expanded="true"]`).click()
                }
                g.printing = false;
            }, 500);
        },
        send(){
            this.view_archive = false;
            this.loading = false;
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#students-ghiab-manual")
        },
        sendModal(){
          var g =this;
          if(localStorage.getItem('send')){
              g.selected = JSON.parse(localStorage.getItem('send')).selected
          }
          g.messageChanged()
        },
        sendNow(method){
            var g = this;
            var c = []

            g.senders.forEach(function(s){
                c.push({
                    phone: window.location.href.includes('localhost') ? '201147028564' : s.phone,
                    message: s.message
                })
            })
            g.loading = true
            $.post(api + '/api/messages/short', {
                school_id: g.school_id,
                arr: JSON.stringify(c),
            }).then(function(r){
                c = r.response
            $.post(api + '/api/messages/send', {
                school_id: g.school_id,
                arr: JSON.stringify(c),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'teachers'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ عند اختصار الروابط')
            })
        },
        refresh(){
          this.senders = JSON.parse(JSON.stringify(this.senders))
        },
        messageChanged(){
          var g = this;
          g.senders = g.senders.map(function(student){
            if(student.isTeacher){
                student.message = g.message.replace("{link}", `({${g.href + '/teacher-weeklyplan/' + g.school_id + "/" + student.id}})`)
            }else{
                if(g.selected == 'whatsapp-api'){
                    g.message = `الخطة الاسبوعية\n{link}`
                }
                student.message = g.type == 1 ? g.message.replace("{link}", `({${g.href + '/weeklyplan/' + g.school_id + "/" + encodeURIComponent(student.row_name)}})`) : g.message.replace("{link}", g.selected == 'whatsapp-api' ? `[file]${g.pdfs[student.row_name]}[/file]` : `({${g.pdfs[student.row_name]}})`);
            }
            return student;
          })
          g.refresh()
        },
        sendtostudents(){
            var g = this;
            if(!g.selected_classes.length){
                return alert('يجب اختيار فصل واحد على الاقل.')
            }
            g.senders = g.students.filter(function(x){
                if(g.selected_classes.includes(x.row_name)){
                    return x;
                }else{
                    return false
                }
            })
            if(g.type == 1){
                g.send();
            }else{
                var xc = 0;
                function cc(a){
                    g.pdf_loading = true;
                    $.post(api + '/api/reports/parent/weeklyplan-pdf/generate', {
                        school_id: g.school_id,
                        classroom: a
                    }).then(function(r){
                        g.pdfs[a] = r.response
                        g.pdfs = JSON.parse(JSON.stringify(g.pdfs))
                        if(g.selected_classes[g.selected_classes.length -1] != a){
                            xc++
                            cc(g.selected_classes[xc])
                        }else{
                            g.pdf_loading = false;
                            g.send()
                        }
                    }).fail(function(){
                        alert("حدث خطأ في الاتصال")
                    })
                }
                cc(g.selected_classes[0])
            }
        },
        exportToExcel() {
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>';
                excelTemplate = excelTemplate.replaceAll("<img", "<br style='display:none'")
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
            setTimeout(() => {
                window.location = window.location.href
            }, 1000);
        },
        noRepeat(arr, classname){
            var g = this;
            var dones = []
            return arr.map(function(x){
                if(dones.includes(x.Title)){
                    return false;
                }else{
                    dones.push(x.Title)
                    return x;
                }
            }).filter(function(x){return x});
        },
        subjectDate(d, d2){
            // return new Date(new Number(d.replace("Date(", "").replace(')', '').replace('/', '').replace('/', ''))).toISOString().split("T")[0] == d2
            return d.split("T")[0]
        },
        subjectsNames(r){
            var arr = [];
            for (const [key, valu] of Object.entries(r)) {
                for (const [key, valuex] of Object.entries(valu)) {
                if(!arr.includes(key)){
                        arr.push(key?.trim())
                    }
                }
            }
            return arr;
        }
    }
}
</script>

<style>
#date2{
    display: none;
}
th,td{
    text-align: center;
}
[aria-expanded]:hover{
    background: #eee !important;
}
body{
    background: #fafafa !important;
}
.hoverxr{
    transition: transform .2s;
}
.hoverxr:hover{
    transform: scale(1.1);
    cursor: pointer;
    background: #eee;
}
</style>